import { Component, Input, OnInit } from '@angular/core';
import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Tag } from '@parashift/shared/models';
import { TagsRegister } from '@parashift/shared/services';

@Component({
  selector: 'tag-badges',
  templateUrl: './tag-badges.component.html',
  standalone: true,
  imports: [NgFor, TooltipModule, FaIconComponent, NgIf, SlicePipe]
})
export class TagBadgesComponent implements OnInit {
  @Input() tags: string[];
  tagModels: Tag['plainModel'][];
  slicedTags: Tag['plainModel'][] = [];

  constructor(private tagsRegister: TagsRegister) {}

  ngOnInit() {
    const tags = this.tagsRegister.getByNames(this.tags);

    if (tags.length > 3) {
      this.tagModels = tags.slice(0, 2);
      this.slicedTags = tags.slice(2);
    } else {
      this.tagModels = this.tagsRegister.getByNames(this.tags);
      this.slicedTags = [];
    }
  }
}
