<ng-container *ngFor="let tag of tagModels">
  <span class="badge rounded-pill text-bg-light me-1" [tooltip]="tag.name.length > 10 ? tag.name : ''" container="body">
    <fa-icon
      [icon]="['fas', 'tag']"
      [fixedWidth]="true"
      class="me-1">
    </fa-icon>{{ tag.name | slice:0:10 }}<ng-container *ngIf="tag.name.length > 10">...</ng-container>
  </span>
</ng-container>
<ng-container *ngIf="slicedTags.length > 0">
  <ng-template #slicedTagsTooltip><ng-container *ngFor="let tag of slicedTags; let i = index"><ng-container *ngIf="i > 0"><br></ng-container><fa-icon
    [icon]="['fas', 'tag']"
    [fixedWidth]="true"
    class="me-1">
  </fa-icon>{{ tag.name}}</ng-container></ng-template>
  <span class="badge rounded-pill text-bg-light me-1" [tooltip]="slicedTagsTooltip" container="body">
    <fa-icon
      [icon]="['fas', 'tag']"
      [fixedWidth]="true"
      class="me-1">
    </fa-icon>More...
  </span>
</ng-container>
